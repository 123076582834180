<template>
    <v-app>        
        <div class="jh-mssg-list-area" id="scroll-target" v-scroll:#scroll-target="onScroll">
            <div class="is-mssg-divider">{{ historyDate }}</div>
            <div class="jh-mssg-list">
                <template v-for="(item, index) in talkHistory">
                    <div :class="item.SNDRCV_CD == 'RCV' ? 'from-left' : 'from-right'" :key="index">
                        <div v-if="item.SNDRCV_CD == 'RCV'" class="is-name">{{ item.CUSTOMER_NM }}</div>
                        <div v-if="item.SNDRCV_CD == 'SND'" class="is-name">{{ item.SEND_USER_NM }}</div>

                        <div v-if="(item.TYPE == 'IM' || item.TYPE == 'photo')" class="is-mssg">
                            <img v-auth-image="getImageSrc(item)">
                            <div class="is-view-all" @click="showImgContent(item)">전체보기<i class="jh-icon-angle-right-xs"></i></div>
                        </div>

                        <div v-if="(item.TYPE != 'IM' && item.TYPE != 'photo')" class="is-mssg">{{ item.CONTENT }}
                            <div v-if="item.CONTENT.length > 1000" class="is-view-all" @click="showLongContent(item.CONTENT)">전체보기<i class="jh-icon-angle-right-xs"></i></div>
                        </div>
                        <div>
                            <!-- <button class="jh-btn is-icon" v-on="on"><i class="jh-icon-close-xs"></i></button> -->
                            <span class="is-mssg-time">{{ item.CHAT_DATE }}</span>
                        </div>
                    </div>
                </template>
            </div>
            <!--
            <div class="is-mssg-divider">상담이 종료되었습니다.</div>
            -->
            <div id="scrollMe" ref="scrollToMe"></div>          
            <v-btn class="jh-btn is-to-bottom" v-if="ToBottomButton" @click="toBottomClick"><i class="jh-icon-arrow-down-lg"></i></v-btn>

            <!-- 장문 전체보기 -->
            <v-dialog v-model="dialogTotalMsg" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="jh-dialog-wrap has-body-scroll">            
                <div class="jh-dialog-header">
                    <h1>장문대화 전체보기</h1>
                    <div class="is-right">
                        <button type="button" class="jh-btn is-icon" title="닫기" @click="dialogTotalMsg=false"><i class="jh-icon-close-lg is-white"></i></button>
                    </div>
                </div>
                <div class="jh-dialog-body"><div class="jh-mssg-all">{{msg_all}}</div></div>
            </v-dialog>

            <!-- 이미지 전체보기 -->
            <v-dialog v-model="dialogTotalImage" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="jh-dialog-wrap has-body-scroll">                
                <div class="jh-dialog-header">
                    <h1>이미지 보기</h1>
                    <div class="is-right">
                        <button type="button" class="jh-btn is-icon" title="닫기" @click="dialogTotalImage=false"><i class="jh-icon-close-lg is-white"></i></button>
                    </div>
                </div>
                <div class="jh-dialog-body"><img :src="image_all" style="width: 650px;"></div>                
            </v-dialog>
        </div>
        <div class="jh-mssg-frame">
            <div class="jh-mssg-form" style="max-height: 230px;">
              <v-textarea 
                class="jh-form is-trns" 
                noResize 
                auto-grow 
                rows="3" 
                row-height="25" 
                placeholder="상담내용을 입력하세요." 
                v-model.trim="CHAT_MSG" 
                v-on:keyup.enter="sendMsg"
                v-on:keyup.ctrl="findCommand('ctrl', $event)"
                v-on:keyup.alt="findCommand('alt', $event)"
                v-on:keyup.shift="findCommand('shift', $event)"
              >
              </v-textarea>
            </div>
            <div class="jh-mssg-form-footer">
              <div class="is-left">
                <!-- <v-btn class="jh-btn is-icon" title="이미지선택" @click="showDialog('P101101')"><i class="jh-icon-chat-image"></i></v-btn>
                <v-btn class="jh-btn is-icon" :title="[mssgStop ? '메시지재개' : '메시지중단']" @click="SystemMsgSkip"><i :class="[mssgStop ? 'jh-icon-chat-play' : 'jh-icon-chat-stop']"></i></v-btn>
                <v-btn class="jh-btn is-icon" title="종료메시지전송" @click="sendEndLink"><i class="jh-icon-chat-complete"></i></v-btn>
                <v-btn class="jh-btn is-icon" title="상담전달" @click="clickTransfer"><i class="jh-icon-chat-transfer"></i></v-btn> -->
              </div>
              <div class="is-right"><v-btn class="jh-btn is-main" @click="sendMsg">전송</v-btn></div>
            </div>
        </div>
    </v-app>
</template>

<script>
import { mapGetters } from "vuex"
import { mixin } from "@/mixin/mixin.js"
import apiChat from "../../store/apiChatParamUtil.js";
import Vue from "vue";
import VueAuthImage from 'vue-auth-image';
import axios from "axios";

import { eventBus } from "@/store/eventBus.js"; //Event Bus
import { stompUtil } from "@/store/stomp-util.js";

Vue.use(VueAuthImage);

export default {
  name: "E101103P01", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {},
  data() {
    return {
        CHAT_MSG: '',
        talkHistory: [],
        Imgdata: {},
        ASP_NEWCUST_KEY:'',
        TALK_CONTACT_ID:'',
        ASP_SENDER_KEY:'',
        TALK_USER_KEY:'',
        CUSTOMER_NM:'',
        CUSTOMER_ID:'',
        USER_NM:'',
        USER_ID:'',

        CUST:this.fullCust(),

        //추가
        ToBottomButton: false,
        dialogTotalMsg: false,
        dialogTotalImage: false,
        msg_all: "",
        image_all: null,
        historyDate: "",
        chatInfo: {},
        params: {},
        HAS_BTN: false,
        headerTitle: '',
        reConFlag: true,
    }
  },
  created() {
    // 토큰 동기화를 위해 메인창에 window 객체에 팝업 windows 객체 저장
    // window 팝업에서 palette api를 호출할 경우 필수
    if (opener) {
        if (!opener.popups) opener.popups = [];
            opener.popups.push(self);
    }
  },
  mounted() {
    const popupParams = JSON.parse(localStorage['CHAT_POPUP_PARAM']);
    
    this.ASP_NEWCUST_KEY = popupParams.ASP_NEWCUST_KEY
    this.TALK_CONTACT_ID = popupParams.TALK_CONTACT_ID
    this.ASP_SENDER_KEY = popupParams.ASP_SENDER_KEY
    this.TALK_USER_KEY = popupParams.TALK_USER_KEY
    this.CUSTOMER_NM = popupParams.CUSTOMER_NM
    this.CUSTOMER_ID = popupParams.CUSTOMER_ID
    this.USER_NM = popupParams.USER_NM
    this.USER_ID = popupParams.USER_ID
    this.HAS_BTN = true //popupParams.HAS_BTN
    this.chatInfo = popupParams.chatInfo
    this.params = popupParams.params

    this.getTalkHistList();

    if(this.HAS_BTN){
      //this.changeUserStat("CHATON");
      this.setTalkEnv();
      this.connect();
      this.HAS_BTN = false;
    }

    window.addEventListener('beforeunload', function(event) {
      this.disconnect()
    })
    /**
     * 채팅 메시지 인입
    
    eventBus.$on("busThirdInoutCallback", (message) => {
      this.getTalkList();
      this.getTalkHistList();

      let recvJson = JSON.parse(message.body);
      let destArray = message.headers.destination.split("/")
      if(recvJson.chatEvent == "EXPIRED_SESSION_CUST"){
        //message.headers.subscription.unsubscribe();
        if(recvJson.userId == this.USER_ID){
          this.$emit("chatEnd", this.chatData);
        }

      }
    });
    */
    /**
       * 채팅 메시지 인입 
       */
      eventBus.$on('busInoutCallback', (message) => {
        this.getTalkList();
        this.getTalkHistList();

        let recvJson = JSON.parse(message.body);
        let destArray = message.headers.destination.split("/")
        if(recvJson.chatEvent == "EXPIRED_SESSION_CUST"){
          //message.headers.subscription.unsubscribe();
          if(recvJson.userId == this.USER_ID){
            this.$emit("chatEnd", this.chatData);
          }

        }
      });

    setTimeout(() => { 
        this.showToBottom();
    }, 1000);
  },
  methods: {
      showImgContent(item){
          this.image_all = this.getImageSrc(item);
          this.dialogTotalImage=true;
      },
      showLongContent(content){
          this.msg_all = content;
          this.dialogTotalMsg=true;
      },
      /**
       * 이미지 src 가져오기
       */
      getImageSrc: function(message)  {
          var strImageUrl = "";

          // Authorization Header 추가 (403 Forbidden 방지)
          const token = window.sessionStorage.getItem("token");
          var authHeader = "bearer " + token;
          axios.defaults.headers.common['Authorization'] = authHeader;

          strImageUrl = `/api/file/chat/images/${message.FILE_GROUP_KEY}/${message.FILE_KEY}`;

          return strImageUrl;

      },
    //상담이력 조회
    getTalkHistList() {
      apiChat
        .post(
          "api/chat/main/cnslt-cn/inqire",   //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            TALK_CONTACT_ID: this.TALK_CONTACT_ID,
            TALK_USER_KEY: this.HAS_BTN == true ? this.USER_ID : this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_ID: this.HAS_BTN == true ? this.USER_ID : this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.ASP_NEWCUST_KEY,
            USER_ID: this.HAS_BTN == true ? this.USER_ID : this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action:'api/chat/main/cnslt-cn/inqire',
            method:'post'
          },
          { head: {} }
        )
        .then((response) => {
          //console.log("상담이력<<<<>>>>", response.data.data);
          //console.log("index", index);
          this.talkHistory = response.data.data;
           if(response.data.data != undefined){
            for(var i = 0; i < this.talkHistory.length; i ++){
              //  this.talkHistory[i].CONTENT = response.data.data[i].CONTENT.replace(/\\\\/g, "\\").replace(/\\r\\n/g, "\\n").replace(/\\/g, "");

                this.talkHistory[i].CONTENT = response.data.data[i].CONTENT.replaceAll("\\n", "\n");
                this.talkHistory[i].CONTENT = response.data.data[i].CONTENT.replaceAll("\\", "");
                this.talkHistory[i].CONTENT = response.data.data[i].CONTENT.replace(/\\\\/g, "\\").replace(/\\r\\n/g, "\n").replace(/\\/g, "");                        
            }
           }
          //console.log("이력메시지목록<<<<>>>>", this.talkHistList);
          //날짜 세팅
          var dateTime = new Date(this.talkHistory[0].IT_PROCESSING);
          this.historyDate = dateTime.getFullYear() + "년 " + (dateTime.getMonth() + 1) + "월 " + dateTime.getDate() + "일";  
          
          this.scrlToBottom();
          /*
          if(this.HAS_BTN){
            
          } else {
            this.scrlToTop();
          }
          */
        })
        .catch((err) => {
          this.mainAlert(err);
        });
    },

    /** 상담설정 관련 정보 get */
    setTalkEnv() {
      apiChat
        .post("api/chat/common-vue/env/inqry", {
          ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        })
        .then((ret) => {
          // 서버 error 체크
          if (ret.data.error != null && ret.data.error != "") {
            if (ret.data.error == "-999") {
              this.mainAlert("챗 상담 사용자 정보 Error(Talk Token)");
            }
          } else {
            let retObj = Object.values(ret.data.data);

            if (retObj != null && retObj.length > 0) {
              this.$store.state.readyTimeout =
                retObj[0].RETRY_READY_MAX_TIMEOUT;
            }

            if (retObj != null && retObj.length > 0) {
              this.$store.state.messages.blobYn = retObj[0].BLOB_YN;
            }
          }
        });
    },

    //
    //3자채팅 웹소캣 연결
    /**
     * websocket connect
     * @param connectCallback   연결 성공 콜백
     * @param errorCallback     연결 실패 콜백 ( 재연결 시도 )
     */
    connect() {
      console.log("this.CUSTOMER_NM ", this.CUSTOMER_NM)
      console.log("this.CUSTOMER_ID ", this.CUSTOMER_ID)
      let geUserId = this.USER_ID;

      //const subScribeUrl = this.baseSocketUrl + "queue/ready/" + geUserId;                //"http://local.hkpalette.com:8443/queue/ready/"
      let subScribeUrl = "/topic/inout/" + geUserId;

      let chatMessage = {
        chatType: "CONSULT",
        userId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        // userName: geUserId,
        userNickname: geUserId,
        talkContactId: this.TALK_CONTACT_ID ,
        aspSenderKey: "001",
        userKey : this.TALK_USER_KEY,
        customerNm : this.CUSTOMER_NM,
        customerId : this.CUSTOMER_ID,
        userName : this.$store.getters["userStore/GE_USER_ROLE"].userName
      };

      //stompUtil.token = this.$store.state.messages.token; //SOCKET 연결 시 사용자 SOCKET Token 세팅
      stompUtil.token = window.sessionStorage.getItem("token"); //SOCKET 연결 시 사용자 SOCKET Token 세팅
      stompUtil.userId = geUserId;
      stompUtil.aspNewCustKey = this.CUST;

      stompUtil.connect(
        () => {
          // 연결성공( 재연결 포함 )
          //console.log(`************************connect stompUtil 연결성공!!!!`)

          stompUtil.setConnected(true);

          stompUtil.subscribe(subScribeUrl, chatMessage); // ready uri sub

          let subList = this.$store.getters["messages/GE_SUBSCRIPTION_LIST"];
          stompUtil.subscribeAfterReconnect(subList); // 재연결 후 기존 sub 목록을다시 sub 해줌.최초는 대상이 없으므로 무관

          ///// 선택된 상담이 잇다면 재조회 (재연결시...)
          ////if( this.store.getters.getContactInfo().talk_contact_id != null && .getters.getContactInfo().talk_contact_id != '' ) {
          /////  eventBus.refreshChatContent(this.store.getters.getContactInfo().talk_contact_id);
          ////}
          this.getTalkList();
        },
        (error) => {
          //연결실패
          //console.log(`************************connect stompUtil 연결 실패ㅠㅠㅠㅠㅠ`, error)
          if (error != null) {
            //console.log(error);
            stompUtil.setConnected(false);

            // 재연결 시도
            if(this.reConFlag){
              setTimeout(this.connect, 3000);
              this.reConFlag = false;
            }
            
          }
        },
      );
    },

    /**
     * 웹소켓 연결종료
     * Stomp / Socket-Io
     */
    disconnect() {
      //this.changeUserStat("CHATOFF");
      if (stompUtil.isConnected()) {
        stompUtil.disconnect();
      }
    },

    /*
    * 메시지 전송
    *
    * */
    sendMsg() {
      let msgcopy = this.CHAT_MSG;
      if (msgcopy.replace(/(?:\r\n|\r|\n)/g, "").length === 0) {
        return false;
      }

      // let MSG_INFO = [];
      // if(msgcopy.length < 1000){
      //   MSG_INFO[0] = {
      //     msg: msgcopy,
      //     message_type: "TX",
      //     SNDRCV_CD: "SND",
      //     type: "message",
      //   };
      // } else if(msgcopy.length >= 1000){
      //   let ARR_NUM = (msgcopy.length / 1000) + 1;
      //   for(let i=0;i<ARR_NUM;i++){
      //     MSG_INFO[i] = {
      //     msg: msgcopy.substring((i*1000), 1000),
      //     message_type: "TX",
      //     SNDRCV_CD: "SND",
      //     type: "message",
      //   };
      //   }
      // }

      let MSG_INFO = {'msg' : msgcopy, message_type : 'TX', SNDRCV_CD: 'SND', type : 'message'}

      let CHAT_INFO = {
        talk_contact_id: this.TALK_CONTACT_ID,
        talk_sender_key: this.ASP_SENDER_KEY,
        call_typ_cd: "TTALK",
        aspNewCustKey: this.ASP_NEWCUST_KEY,
        talk_user_key: this.TALK_USER_KEY,
        SEND_USER_NM: this.$store.getters["userStore/GE_USER_ROLE"].userName,
        //token: this.$store.state.messages.token,
        token: window.sessionStorage.getItem("token"),
        userId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      };
      console.log(`input 채팅 CHAT_INFO : `, CHAT_INFO)

      // let chatmsg = this.$store.getters["messages/GE_MSG_OBJ"]({
      //   user_key: CHAT_INFO.talk_user_key,
      //   sndrcv_cd: "snd",
      //   type: MSG_INFO.message_type,
      //   content: MSG_INFO.msg,
      //   talk_contact_id: CHAT_INFO.talk_contact_id,
      //   IMAGE_TALK_PATH:
      //     MSG_INFO.IMAGE_TALK_PATH != "" ? MSG_INFO.IMAGE_TALK_PATH : "",
      //   FILE_GROUP_KEY:
      //     MSG_INFO.FILE_GROUP_KEY != "" ? MSG_INFO.FILE_GROUP_KEY : "",
      //   FILE_KEY: MSG_INFO.FILE_KEY != "" ? MSG_INFO.FILE_KEY : "",
      //   FILE_BLOB: MSG_INFO.FILE_BLOB != "" ? MSG_INFO.FILE_BLOB : "",
      //   FILE_EXTS: MSG_INFO.FILE_EXTS != "" ? MSG_INFO.FILE_EXTS : "",
      //   //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
      //   SEND_USER_NM: CHAT_INFO.SEND_USER_NM,
      //   SEND_USER_SHORT_NM: "나",
      //   consultEnd: MSG_INFO.consultEnd,
      //   ASP_NEWCUST_KEY: CHAT_INFO.aspNewCustKey,
      // });

      // let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({
      //   talk_contact_id: CHAT_INFO.talk_contact_id,
      // });


          // let MSG_INFO = {'msg' : msgcopy, message_type : 'TX', SNDRCV_CD: 'SND', type : 'message'}
          // let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
          //                 , 'talk_sender_key' : this.CLK_SENDER
          //                 , 'call_typ_cd' : this.CALL_TYP_CD
          //                 , 'aspNewCustKey' : this.CUST
          //                 , 'talk_user_key': this.CLK_USER
          //                 , 'SEND_USER_NM' : this.USER_NM
          //                 , 'token' : this.$store.state.messages.token
          //                 , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
          //                 };
  
          let chatmsg = this.$store.getters['messages/GE_MSG_OBJ',{
                        user_key: CHAT_INFO.talk_user_key
                        , sndrcv_cd: 'snd'
                        , type: MSG_INFO.message_type
                        , content: MSG_INFO.msg
                        , talk_contact_id: CHAT_INFO.talk_contact_id
                        , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                        , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                        , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                        , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                        , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                        //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                        , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                        , SEND_USER_SHORT_NM : '나'
                        , consultEnd : MSG_INFO.consultEnd
                        , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                    }];
  
          let msgObj = this.$store.getters['messages/GE_CONTACT_MSG', { talk_contact_id: CHAT_INFO.talk_contact_id }];


          stompUtil.sendMessage(MSG_INFO,CHAT_INFO, chatmsg, msgObj)



      // for(let n=0;n<MSG_INFO.length;n++){
      //   stompUtil.sendMessage(MSG_INFO[n], CHAT_INFO, chatmsg, msgObj);
      // }
      this.CHAT_MSG = "";

      this.getTalkHistList();
    },

    /**
     * 채팅가능상태 만들기
     */
    changeUserStat(chat) {
      apiChat
        .post(
          "api/chat/main/cnslr-sttus/regist",
          {
            USER_STATUS_CD: chat,
            USER_ID: this.USER_ID,
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
          },
          {
            head: {
              SERVICE: "api.chat.main.cnslr-sttus",
              METHOD: "regist",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((ret) => {
          if(chat == "CHATON"){
            console.log("상담 가능");
          } else if(chat == "CHATOFF"){
            console.log("상담 불가능");
          }
        });
    },
    
    scrlToTop() {
      this.scrollTarget = document.querySelector(
        "#scroll-target"
      );

      if (this.scrollTarget) {
        this.scrollTarget.scrollTop = 0;
      }
    },

    scrlToBottom() {
      this.scrollTarget = document.querySelector(
        "#scroll-target"
      );

      if (this.scrollTarget) {
        this.scrollTarget.scrollTop = this.scrollTarget.scrollHeight;
      }
    },

    //상담원 전체 고객사 키
    fullCust() {
      let CUST = "";
      for (
        let i = 0;
        i < this.$store.getters["userStore/GE_USER_ROLE"].company.length;
        i++
      ) {
        if (i == 0) {
          CUST = this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
        } else {
          CUST =
            CUST +
            "," +
            this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
        }
      }

      return CUST;
    },

    //상담 목록 조회
    getTalkList(detail) {
      apiChat
        .post(
          "api/chat/main/cnslt/inqire", //api url입력
          {
            TYPE: "ready;talking;callback;post",
            SORT_BY: "TALK_DIST_DT",
            DECENDING: "ASC",
            TALK_USER_KEY: "",
            ASP_USER_ID: this.USER_ID,
            ASP_CUST_KEY: this.CUST,
            ST_DATE: '',
            END_DATE: '',
            CALL_TYP_CD: '',
          },
          { head: {} }
        )
        .then((response) => {

          this.$store
            .dispatch("messages/AC_INIT_TALK_LIST", response.data.data)
            .then(() => {
              this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {
                selected_stat: this.selected_stat,
                userId: this.$store.getters["userStore/GE_USER_ID"],
                aspCustKey: this.CUST,
              });
            });

          this.scrlToBottom();
        })
        .catch((err) => {
          console.log("결과<<<<<", err);
          this.mainAlert(err);
        });
    },

//종료링크 보내기
    sendEndLink() {
      let strMsg = "고객님께 종료 버튼을 전송하였습니다.";

      let MSG_INFO = {
        msg: strMsg,
        message_type: "LI",
        SNDRCV_CD: "SND",
        type: "chatClose",
      };
      let CHAT_INFO = {
        talk_contact_id: this.TALK_CONTACT_ID,
        talk_sender_key: this.ASP_SENDER_KEY,
        call_typ_cd: "TTALK",
        aspNewCustKey: this.ASP_NEWCUST_KEY,
        talk_user_key: this.TALK_USER_KEY,
        SEND_USER_NM: this.USER_ID,
        token: window.sessionStorage.getItem("token"),
        userId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      };


      
      let chatmsg = this.$store.getters["messages/GE_MSG_OBJ"]({
        user_key: CHAT_INFO.talk_user_key,
        sndrcv_cd: "snd",
        type: MSG_INFO.message_type,
        content: MSG_INFO.msg,
        talk_contact_id: CHAT_INFO.talk_contact_id,
        IMAGE_TALK_PATH:
          MSG_INFO.IMAGE_TALK_PATH != "" ? MSG_INFO.IMAGE_TALK_PATH : "",
        FILE_GROUP_KEY:
          MSG_INFO.FILE_GROUP_KEY != "" ? MSG_INFO.FILE_GROUP_KEY : "",
        FILE_KEY: MSG_INFO.FILE_KEY != "" ? MSG_INFO.FILE_KEY : "",
        FILE_BLOB: MSG_INFO.FILE_BLOB != "" ? MSG_INFO.FILE_BLOB : "",
        FILE_EXTS: MSG_INFO.FILE_EXTS != "" ? MSG_INFO.FILE_EXTS : "",
        //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
        SEND_USER_NM: CHAT_INFO.SEND_USER_NM,
        SEND_USER_SHORT_NM: "나",
        consultEnd: MSG_INFO.consultEnd,
        ASP_NEWCUST_KEY: CHAT_INFO.aspNewCustKey,
      });

      let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({
        talk_contact_id: CHAT_INFO.talk_contact_id,
      });

      stompUtil.sendMessage(MSG_INFO, CHAT_INFO, chatmsg, msgObj);
    },

    showDialogImg(menu) {
      let dataObj = {
        CLK_CUST: this.ASP_NEWCUST_KEY,
        CLK_CHAT: this.TALK_CONTACT_ID,
        CLK_USER: this.TALK_USER_KEY,
        CLK_SENDER: this.ASP_SENDER_KEY,
        CLK_MSGSKIP: '',
        CLK_STAT: '',
        CLK_ICON: '',
        CLK_IMG: '',
        STATUS: "CHATON",
      };
      this.Imgdata = dataObj;
      console.log("open img!");
      console.log(this.Imgdata);
      this[`dialog${menu}`] = true;
    },

    /**
     * 모달창 숨기기
     */
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },

    //상담 종료(상담중 채팅을 후처리 상태로 만들기)
    endTalk(){

      apiChat.post("api/setting/consulttype/manage/inqire",   //api url입력
      {
        method : 'post',
        action : "api/chat/main/end-mssage/inqire",
        system_id : 'MSG34895' ,
        ASP_NEWCUST_KEY : this.ASP_NEWCUST_KEY,
        ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        ASP_CUST_KEY : this.CUST,
        USER_ID : this.$store.getters["userStore/GE_USER_ROLE"].userId,
      },
      {head: {
      }})
      .then((response) => {
        let MSG_INFO = {
          'type' : 'system',
          consultEnd: 'Y',
          msg: response.data.data,
          message_type: "TX",
          SNDRCV_CD: "SND",
          type: "message",
        };
        let CHAT_INFO = {
          talk_contact_id: this.TALK_CONTACT_ID,
          talk_sender_key: this.ASP_SENDER_KEY,
          call_typ_cd: "TTALK",
          aspNewCustKey: this.ASP_NEWCUST_KEY,
          talk_user_key: this.TALK_USER_KEY,
          SEND_USER_NM: this.USER_ID,
          token: window.sessionStorage.getItem("token"),
          userId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        };
        let chatmsg = this.$store.getters["messages/GE_MSG_OBJ"]({
          user_key: CHAT_INFO.talk_user_key,
          sndrcv_cd: "snd",
          type: MSG_INFO.message_type,
          content: MSG_INFO.msg,
          talk_contact_id: CHAT_INFO.talk_contact_id,
          IMAGE_TALK_PATH:
            MSG_INFO.IMAGE_TALK_PATH != "" ? MSG_INFO.IMAGE_TALK_PATH : "",
          FILE_GROUP_KEY:
            MSG_INFO.FILE_GROUP_KEY != "" ? MSG_INFO.FILE_GROUP_KEY : "",
          FILE_KEY: MSG_INFO.FILE_KEY != "" ? MSG_INFO.FILE_KEY : "",
          FILE_BLOB: MSG_INFO.FILE_BLOB != "" ? MSG_INFO.FILE_BLOB : "",
          FILE_EXTS: MSG_INFO.FILE_EXTS != "" ? MSG_INFO.FILE_EXTS : "",
          //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
          SEND_USER_NM: CHAT_INFO.SEND_USER_NM,
          SEND_USER_SHORT_NM: "나",
          consultEnd: MSG_INFO.consultEnd,
          ASP_NEWCUST_KEY: CHAT_INFO.aspNewCustKey,
        });

        let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({
          talk_contact_id: CHAT_INFO.talk_contact_id,
        });

        stompUtil.sendMessage(MSG_INFO, CHAT_INFO, chatmsg, msgObj);

        this.$emit("chatEnd", this.chatData);
      })
      .catch((err) => {
        this.mainAlert(err);
      })
    },
    showAlertEndTalk() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '상담을 종료하시겠습니까?',
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYesEndTalk,
        callNo: this.callNoEndTalk,
      });
    },
    callYesEndTalk() {
      //console.log("call yes");
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.endTalk();
    },
    callNoEndTalk() {
      //console.log("call No");
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    toBottomClick() {
        const element = document.getElementById("scrollMe");       
        setTimeout(() => { 
            element.scrollIntoView({behavior: "smooth"});
            this.ToBottomButton = false;  
        }, 500);
    },     
    onScroll (e) {
        var scrollTop = e.target.scrollTop;
        var innerHeight = e.target.clientHeight;
        var scrollHeight = e.target.scrollHeight;
        if(scrollTop + innerHeight >= scrollHeight -70){
            this.ToBottomButton = false;
        }else{
            this.ToBottomButton = true;
        }
    },
    showToBottom (){            
        var container = document.querySelector("#scroll-target");
        var innerHeight = container.clientHeight;
        var scrollHeight = container.scrollHeight;
        if(innerHeight < scrollHeight){
            this.ToBottomButton = true;
        }else{
            this.ToBottomButton = false;
        }
    },
  },
  filters: {

  },
  computed: {

  },

  watch: {
    /*
    프로퍼티에서 변수로 빠짐
    chatInfo() {
      const popupParams = JSON.parse(localStorage['CHAT_POPUP_PARAM']);
    
      this.ASP_NEWCUST_KEY = popupParams.ASP_NEWCUST_KEY
      this.TALK_CONTACT_ID = popupParams.TALK_CONTACT_ID
      this.ASP_SENDER_KEY = popupParams.ASP_SENDER_KEY
      this.TALK_USER_KEY = popupParams.TALK_USER_KEY
      this.USER_ID = popupParams.USER_ID
      this.HAS_BTN = false //popupParams.HAS_BTN
      this.chatInfo = popupParams.chatInfo
      this.params = popupParams.params
      
      this.getTalkHistList();
      console.log(`watch - chatInfo > this.HAS_BTN : ${this.HAS_BTN}`)

      if(this.HAS_BTN){
        //this.changeUserStat("CHATON");
        this.setTalkEnv();
        this.connect();
      }
    }
    */
  }
}
</script>

<style></style>
